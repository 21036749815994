main {
    background: rgb(17, 16, 16);
     
    min-height: 100vh;
    padding: 20px;
    pointer-events: none;
}
#background-landingPage {
    position: relative;
}
#background-landingPage img {
    width: 100%;
}
#background-landingPage :nth-child(2).lp-mobile-bg {
    display: none;
}
#social-links {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 40px;
}
#social-links img {
    width: 2.2em;
}

h1 {
    padding-top: 200px;
    width: 50%;
    margin: auto;
}
main > h1 {
    position: relative;
}
#container-holders {
    position: absolute;
    width: 40%;
    height: 50px;
    top: 98%;
}
#slider-holders {
    border: solid 1.5px white;
    height: 100%;
    border-radius: 30px;
    position: relative;
    z-index: 1;
}
#slider-holders > p {
    position: absolute;
    top: -30px;
}
#slider-holders > img {
    position: absolute;
}
#slider-holders #bobby {
    right: -90px;
    top: -75px;
    width: 12em;
    z-index: 2;
}

#slider-holders #mini-pickle {
    top: -5px;
    width: 4.5em;
    left: 0;
}
#slider-holders > i {
    color: white;
    position: absolute;
    top: -30px;
    left: 250px;
}
#water-effect {
    background: linear-gradient(125deg, rgb(43, 185, 216), #b31d66);;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 0;
    border-radius: 30px;
}
#holders-info {
    background-color: var(--container-color);
    border: solid 1px white;
    border-radius: 10px;
    width: 20em;
    font-size: 0.8em;
    padding: 10px;
    position: absolute;
    top: -130px;
    left: 250px;
    z-index: 10;
}
#bobby-text {
    background-color: var(--container-color);
    border: solid 1px white;
    border-radius: 10px;
    width: 20em;
    font-size: 0.8em;
    padding: 10px;
    position: absolute;
    bottom: -90px;
    right: 20px;
    z-index: 10;
}

@media screen and (max-width: 769px){
    main {
        min-height: 0vh;
        padding-top: 90px;
    }
    #background-landingPage :nth-child(2).lp-mobile-bg {
        display: block;
    }
    #background-landingPage :nth-child(1).lp-desktop-bg {
        display: none;
    }
    h2 {
        position: relative;
        text-align: center;
        width: 100%;
        font-size: 1.3em;
        padding-top: 40px;
    }
    #social-links {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 50px;
    }
    #container-holders {
        position: relative;
        width: 85%;
        margin: auto;
        margin-top: 80px;
    }
    h1 {
        padding-top: 100px;
        width: 100%;
        font-size: 1.8em;
    }
    #slider-holders #bobby {
        right: -45px;
        top: -40px;
        width: 8em;
        /*
                                                                                  
                                                                          
                                                                          
                                ████████                                  
                              ██        ██                                
                            ██▒▒▒▒        ██                              
                          ██▒▒▒▒▒▒      ▒▒▒▒██                            
                          ██▒▒▒▒▒▒      ▒▒▒▒██                            
                        ██  ▒▒▒▒        ▒▒▒▒▒▒██                          
                        ██                ▒▒▒▒██                          
                      ██▒▒      ▒▒▒▒▒▒          ██                        
                      ██      ▒▒▒▒▒▒▒▒▒▒        ██                        
                      ██      ▒▒▒▒▒▒▒▒▒▒    ▒▒▒▒██                        
                      ██▒▒▒▒  ▒▒▒▒▒▒▒▒▒▒  ▒▒▒▒▒▒██                        
                        ██▒▒▒▒  ▒▒▒▒▒▒    ▒▒▒▒██                          
                        ██▒▒▒▒            ▒▒▒▒██                          
                          ██▒▒              ██                            
                            ████        ████                              
                                ████████                                  
                                                                          
                                                                          
                                                                          

        */
    }
    #slider-holders {
        border: solid 2.5px white;
    }
    #holders-info {
        top: -130px;
        left: 50px;
    }
}