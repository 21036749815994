footer {
    min-height: 30vh;
    background: rgb(74,143,201);
    background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 19%, rgb(0, 0, 0) 100%); 
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

#footer-logo {
    width: 300px;
    height: auto;
}

#footer-container {
    display: flex;
    padding: 10px;
}

#container-footer-text {
    width: 50%;
    margin: auto;
    font-weight: bold;
    font-size: 0.8em;
}
#container-footer-text :nth-child(1) {
    margin-bottom: 20px;
}

@media screen and (max-width: 769px){
    footer {
        padding-top: 100px;
    }
    #footer-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
    }
    #container-footer-text {
        width: 80%;
        font-size: 0.8em;
        margin-top: 30px;
        padding: 10px;
    }
}