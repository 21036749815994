#tokenomics-section {
    background-color: #ff8000;
    background: linear-gradient(0deg, #000000 0%, #000000 79%, #000000 100%);
    min-height: 50vh;
}
#tokenomics-title {
    overflow-wrap: break-word;
    font-size: 8em;
    padding: 10px;
}
#container-contract {
    background-color: var(--container-color);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    gap: 20px;
    font-weight: bold;
}
#wrapper-contract {
    border-radius: 10px;
    width: 605px;
    padding: 2px;
    background: linear-gradient(125deg, rgb(43, 185, 217), #b31d66);
    margin: auto;
    margin-top: 100px;
}

#contract-information {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    gap: 80px;
}

.tokenomics-card {
    background-color: var(--container-color);
    border-radius: 5px;
    width: 250px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    position: relative;
}
.wrapper-card {
    width: 255px;
    height: 155px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}
.border-color1 {
    background: linear-gradient(125deg, rgb(43, 185, 217), #b31d66);
}

.tokenomics-card :nth-child(2) {
    font-size: 1.5em;
}

@media screen and (max-width: 769px){
    #container-contract {
        display: flex;
        flex-direction: column;
        gap: 10px;
        
    }
    #container-contract :nth-child(2) {
        overflow-wrap: break-word;
        font-size: 0.9em;
    }
    #contract-information {
        gap: 30px;
    }
    #wrapper-contract {
        width: 80%;
        margin: auto;
        margin-top: 90px;
        margin: auto;
    }
}