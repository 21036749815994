nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #4A8FC9;
    padding: 20px 80px;
}
nav a img {
    width: 20em;
    position: relative;
}
#navbar {
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: center;
}
#navbar li {
    list-style: none;
}
#navbar li a {
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 600;
    color: rgb(255, 255, 255);
    transition: 0.3s ease-in-out;
    text-transform: uppercase;
    position: relative;
}
#navbar li a:hover, #navbar li a.active {
    font-size: 1.2rem;
}
#navbar li a:hover::after, #navbar li a.active::after  {
    content: "";
    width: 70%;
    height: 2px;
    background: rgb(255, 255, 255);
    position: absolute;
    bottom: -4px;
    left: 10px;
}
#mobile {
    display: none;
}
#mobile i {
    color: rgb(255, 255, 255);
}

@media screen and (max-width: 1069px){
    nav {
        display: flex;
        align-items: center;
        background-color: #000000;
        padding: 10px 20px;
        position: fixed;
        z-index: 10;
        gap: 50px;
        width: 100%;
    }
    nav a img {
        width: 15em;
    }
    #navbar {
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 80px;
        right: -300px;
        width: 300px;
        height: 100vh;
        background-color: #000000;
        box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
        padding: 40px 0 0 10px;
        transition: 0.3s ease-in-out;
        z-index: 10;
    }
    #navbar.active {
        right: 0px;
    }
    #mobile {
        display: block;
    }
    #mobile i {
        font-size: 24px;
        cursor: pointer;
    }
}