#holders-section {
    background-color: #b31d66;
    background: linear-gradient(0deg, #000000 0%, #b31d66 79%, #000000 100%);
    min-height: 100vh;
    padding-top: 50px;
}
#container-holders-possibility {

}
.card-holders {
    background-color: rgb(155, 23, 87);
    width: 60%;
    margin: auto;
    border-radius: 20px;
    border: solid 1px white;
    margin-top: 50px;
    display: flex;
    align-items: center;
    gap: 40px;
}
.card-holders img {
    width: 20em;
    padding: 30px;
    border-radius: 50px;
}
.card-holders-text {
    padding-right: 60px;
    font-weight: 500;
    margin-left: 50px;
}
.card-holders-text-title {
    font-size: 2em;
    font-weight: bold;
    padding-bottom: 100px;
}
#card-holders-text-desc-last {
    padding-top: 20px; 
}
.card-holders-reversed {
    flex-direction: row-reverse;
}

#random-generation {
    width: 60%;
    margin: auto;
    text-align: center;
    min-height: 40vh;
    margin-top: 50px;
}
#image-generated {
    background-color: rgba(0, 0, 255, 0);
    width: 300px;
    height: 300px;
    border-radius: 20px;  
}
#image-generated-random {
    width: 300px;
    height: auto;
    border-radius: 20px;
    border: solid 2px white;
    margin-top: 20px;
    margin-bottom: 20px;
}
#container-result-download {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top-color: #ffffff;
    animation: spin 1s infinite linear;
    margin: auto;
    translate: 0px 80px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  #call-to-action-generate-random {
    font-weight: 600;
    font-size: 1.3em;
    width: 70%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }

@media screen and (max-width: 769px){
    #holders-title {
        overflow-wrap: break-word;
        font-size: 6em;
        padding: 10px;
    }
    .card-holders {
        width: 90%;
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    .card-holders-text {
        padding: 15px;
        text-align: center;
        width: 100%;
        margin-left: 0px;
    }
    .card-holders-text-title {
        font-size: 1em;
        padding-bottom: 30px;
    }
    #random-generation {
        width: 90%;
    }
    #call-to-action-generate-random {
        font-size: 1.3em;
        width: 90%;
        margin-top: 30px;
        margin-bottom: 30px;
      }
}