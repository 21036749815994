.App {
  background-color: #000000;
  min-height: 100vh;
  --white-color: #fff;
  --black-color: hsl(210, 12%, 15%);
  --body-color: hsl(210, 16%, 12%);
  --container-color: hsl(210, 24%, 8%);
}

a, h1, h2, p {
  font-family: "Kufam", "sans-serif";
  color: white;
}

.no-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -ms-user-select: none;
}

h2 {
  position: absolute;
  top: 60%;
  font-size: 2.3rem;
  width: 35%;
}
h1 {
  text-align: center;
  font-size: 2.8em;;
}
h3 {
  font-family: "Anton", "sans-serif";
  text-transform: uppercase;
  font-size: 10em;
  color: white;
  background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    letter-spacing: 10px;
}

.desc-section {
  font-size: 2em;
  font-weight: 600;
  text-align: center;
  width: 70%;
  margin: auto;
}

.btn-holders {
  font-family: 'Kufam', "sans-serif";
  width: 200px;
  height: 60px;
  border-radius: 10px;
  background-color: rgb(155, 23, 87);
  color: white;
  border: solid 1px white;
  font-weight: bold;
}
.btn-holders {
  cursor: pointer;
}
.canvas-fluid {
  position: absolute;
  pointer-events: auto;
}
