
#scroll-slider {
    position: absolute;
    top: 25%;
    left: 30px;
    z-index: 10;
    width: 40px;
    height: 50vh;
    border: solid 1.5px white;
    color: white;
    position: fixed;
    border-radius: 20px;
}

.scroll-slider-container {
    background-color: rgba(0, 255, 255, 0.172);
    width: 100%;
    height: 100%;
}