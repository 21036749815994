#story {
    padding-top: 100px;
    background-color: #04AF06;
    background: linear-gradient(0deg, #000000 0%, #000000 75%, rgb(17, 16, 16) 100%);
    min-height: 100vh;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}

#story-images img {
    width: 100%;
}

@media screen and (max-width: 769px){
    #story {
        padding-top: 50px;
        padding-left: -2Opx;
        padding-right: -20px;
        min-height: 0vh;
        margin-left: 0px;
        margin-right: 0px;
    }
    #story-title {
        overflow-wrap: break-word;
        font-size: 8em;
    }
}