#instructions {
    background-color: #246dea;
    background: linear-gradient(0deg, #000000 0%, #000000 79%, #000000 100%);
    padding-top: 150px;
    min-height: 100vh;
}
h3 {
    text-align: center;
}
.desc-section {
    margin-top: 50px;
}
#container-steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    gap: 20px;
    width: 70%;
    margin: auto;
    margin-top: 120px;
}
.instructions-card {
    background-color: var(--container-color);
    width: 30em;
    border-radius: 20px;
    border: solid 1px white;
    position: relative;
    margin-bottom: 80px;
    mix-blend-mode: difference;
}
.instructions-card::before{
    content: "";
    position: absolute;
    inset: -2px;
    border-radius: inherit;
    background: linear-gradient(125deg, rgb(43, 185, 217), #b31d66);
    z-index: -2;
}
.instructions-card::after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    background-color: var(--container-color);
    z-index: -1;
}
.instructions-card img{
    width: 18em;
    translate: 20px -130px;
}
.instructions-card-title {
    font-weight: bold;
    margin-bottom: 20px;
}
.instructions-card-numb {
    position: absolute;
    top: -70px;
    left: 35px;

    font-family: "Anton", sans-serif;
  text-transform: uppercase;
  font-size: 6em;
  color: white;
  background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    letter-spacing: 10px;
    z-index: 1;
}
.instructions-card-title, .instructions-card-desc {
    translate: 0px -80px;
}
.instructions-card-desc {
    margin-left: 20px;
    margin-right: 20px;
}

@media screen and (max-width: 769px){
    #instructions {
        padding-top: 50px;
    }
    #container-steps {
        width: 80%;
    }
    .instructions-card img{
        width: 12em;
        translate: 0px -80px;
    }
    .instructions-card {
        margin-top: 50px;
        margin-bottom: 0px;
        
    }
    #instructions-title {
        overflow-wrap: break-word;
        font-size: 8em;
        padding: 10px;
    }
    .desc-section {
        font-size: 1.2em;
        font-weight: 600;
        width: 75%;
        margin-top: 50px;
    
      }

    .instructions-card-title, .instructions-card-desc {
        translate: 0px -60px;
        font-size: 0.8em;
    }
}